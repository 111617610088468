"use client";

import React from "react";
import Script from "next/script";
import { ThemeProvider } from "@/components/theme/theme-provider";
import { ClerkProvider } from "@clerk/nextjs";
import localFont from "next/font/local";
import { I18NProvider } from "~/client/providers/I18NProvider";
import "~/libs/i18n/init";
import { TRPCReactProvider } from "~/trpc/react";
import { DM_Sans } from "next/font/google";
import { CookieBotScript } from "~/client/spaces/adsense/CookieBotScript";

export function GoogleAnalytics({ measurementId = "G-VMTX3NQNVY" }) {
  return (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${measurementId}`}
        strategy="afterInteractive"
      />
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${measurementId}');
        `}
      </Script>
    </>
  );
}

const geistSans = localFont({
  src: "./fonts/GeistVF.woff",
  variable: "--font-geist-sans",
  weight: "100 900",
});
const geistMono = localFont({
  src: "./fonts/GeistMonoVF.woff",
  variable: "--font-geist-mono",
  weight: "100 900",
});
const dmSans = DM_Sans({ subsets: ["latin"] });

export default function Layout({ children }) {
  return (
    <html
      lang="en"
      // https://github.com/pacocoursey/next-themes
      suppressHydrationWarning
      className={`${dmSans.className} ${geistSans.variable} ${geistMono.variable} dark text-zinc-950 antialiased dark:bg-zinc-900 dark:text-white lg:bg-zinc-100 dark:lg:bg-zinc-950`}
      style={{ colorScheme: "dark" }}
    >
      <head>
        <meta name="google-adsense-account" content="ca-pub-7112315789769225" />
      </head>
      <body>
        <ClerkProvider
          //https://clerk.com/changelog
          dynamic
          appearance={{
            variables: { colorPrimary: "#000000" },
            elements: {
              formButtonPrimary:
                "bg-black border border-black border-solid hover:bg-white hover:text-black",
              socialButtonsBlockButton:
                "bg-white border-gray-200 hover:bg-transparent hover:border-black text-gray-600 hover:text-black",
              socialButtonsBlockButtonText: "font-semibold",
              formButtonReset:
                "bg-white border border-solid border-gray-200 hover:bg-transparent hover:border-black text-gray-500 hover:text-black",
              membersPageInviteButton:
                "bg-black border border-black border-solid hover:bg-white hover:text-black",
              card: "bg-[#fafafa]",
            },
          }}
        >
          <I18NProvider>
            <ThemeProvider
              attribute="class"
              defaultTheme="dark"
              // enableSystem
              // disableTransitionOnChange
            >
              <TRPCReactProvider>{children}</TRPCReactProvider>
              <GoogleAnalytics />
            </ThemeProvider>
          </I18NProvider>
        </ClerkProvider>
      </body>
    </html>
  );
}
