import { useLocalStorage } from "@uidotdev/usehooks";
import { useCallback } from "react";
import { api } from "~/trpc/react";
import { defaultUserSettings, type UserSettings } from "~/types/userSettings";

export const LOCAL_STORAGE_SETTINGS_KEY = "user-settings";

export function useUpdateUserSettings() {
  const [userSettings, saveUserSettings] = useLocalStorage<
    Required<UserSettings>
  >(LOCAL_STORAGE_SETTINGS_KEY, defaultUserSettings);

  const updateUserSettingsMutation = api.user.updateUserSettings.useMutation();

  const updateUserSettings = useCallback(
    (newSettings: UserSettings) => {
      saveUserSettings((prev) => ({ ...prev, ...newSettings }));
      updateUserSettingsMutation.mutate({
        ...defaultUserSettings,
        ...userSettings,
        ...newSettings,
      });
    },
    [userSettings, saveUserSettings],
  );

  return [
    { ...defaultUserSettings, ...userSettings },
    updateUserSettings,
  ] as const;
}
