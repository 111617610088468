import { z } from "zod";
import type { SelectProfile } from "../../drizzle/schema";

const zodPlaymat = z.object({
  opacity: z.enum(["none", "low", "medium", "high", "full"]),
  position: z.enum(["top", "bottom", "center"]),
  size: z.enum(["cover", "contain", "auto"]),
  hideOverlays: z.boolean().optional(),
  image: z.string(),
});

export const UserSettingsSchema = z.object({
  language: z.enum(["EN", "DE", "FR"]).optional(),
  remoteCursor: z.boolean().optional(),
  tablePerspective: z.boolean().optional(),
  sound: z.boolean().optional(),
  disableLogs: z.boolean().optional(),
  disablePreview: z.boolean().optional(),
  cardsSize: z.enum(["small", "normal", "big"]).optional(),
  sleeve: z.enum(["default", "white", "yellow", "cosmos", "custom"]).optional(),
  playmat: zodPlaymat.optional(),
});

export type Sleeves = NonNullable<z.infer<typeof UserSettingsSchema>["sleeve"]>;
export type Playmat = NonNullable<z.infer<typeof zodPlaymat>>;

export type UserSettings = z.infer<typeof UserSettingsSchema>;

// This is the info every match has for each player
export type PlayerInfo = {
  id: string;
  name: string;
  discordId: string;
  tier: number;
  sleeve: Sleeves;
  playmat: Playmat;
  profile: SelectProfile;
};

const baseUrl = "https://six-inks.pages.dev/assets/images/playmats/";

export const PLAYMAT_IMAGES = {
  "beast-001": baseUrl + "beast-001.avif",
  "belle-001": baseUrl + "belle-001.avif",
  "malificent-001": baseUrl + "malificent-001.avif",
  "maui-001": baseUrl + "maui-001.avif",
  "mickey-001": baseUrl + "mickey-001.avif",
  "moana-001": baseUrl + "moana-001.avif",
  "pooh-001": baseUrl + "pooh-001.avif",
  "rapunzel-001": baseUrl + "rapunzel-001.avif",
  "rapunzel-002": baseUrl + "rapunzel-002.avif",
  "stitch-001": baseUrl + "stitch-001.avif",
  "stitch-002": baseUrl + "stitch-002.avif",
  "tinker-bell-001": baseUrl + "tinker-bell-001.avif",
  default: baseUrl + "default.avif",
  custom: baseUrl + "promo/{{ownerId}}.webp",
  none: "",
} as const;

export const defaultPlaymat = {
  opacity: "medium",
  position: "top",
  size: "cover",
  hideOverlays: false,
  image: PLAYMAT_IMAGES["default"],
} as const;

export const defaultUserSettings: Required<UserSettings> = {
  language: "EN",
  remoteCursor: true,
  tablePerspective: true,
  sound: true,
  disableLogs: false,
  cardsSize: "big",
  disablePreview: false,
  sleeve: "default",
  playmat: defaultPlaymat,
} as const;
